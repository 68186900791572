import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {
  updateAnalyticsClass(newClass: string) {
    const elem = document.getElementById("analytics-tracker") as HTMLElement;
    if (elem) {
        elem.setAttribute("class", "")
        elem.classList.add(newClass)
    }
  }
}

