import { share, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';

import { Observable, timer, MonoTypeOperatorFunction, of, Subject, throwError, BehaviorSubject, ReplaySubject } from 'rxjs';
import {
    switchMapTo, map, repeatWhen, skipWhile, take,
    switchMap, retry,
    takeUntil, filter, catchError
} from 'rxjs/operators';
import { IQueuePoll } from '../interfaces/queue-poll.interface';
import { IQuery } from '../interfaces/query.interface';
// @ts-ignore

export class QueuePollClass {
    // tslint:disable-next-line:no-any
    destroy$: Subject<boolean> = new Subject<boolean>();
    public $resolves: BehaviorSubject<IQuery | null> = new BehaviorSubject<IQuery | null>(null);
    public hasValue: ReplaySubject<boolean> = new ReplaySubject(1);
    public hasReInit = false;
    public pollCount$: Subject<number> = new Subject<number>();
    val = 0;
    // tslint:disable-next-line:no-any
    startPollList: Observable<any> = of(null);
    http: HttpClient;
    constructor(http: HttpClient) {
        this.http = http;
    }
    // tslint:disable-next-line:no-any
    createPoll(form: IQueuePoll): any {
        this.startPollList.subscribe(val => {
            if (val === null && this.val === 0) {
                this.startPolling(form.id, form.url);
            }
        });
    }
    // tslint:disable-next-line:no-any
    startPolling(id: string, url: string): void {
        // console.log({id, url});
        this.startPollList = timer(100, 4000).pipe(
            // tap(val => console.log(`Start POLL With: ${JSON.stringify({val:this.val})}`)),
            tap(val => this.val++),
            tap(val => this.pollCount$.next(this.val)),
            switchMap(() => this.http.get(`${url}?modifiedOn=${new Date().toISOString()}`)),
            retry(),
            share(),
            takeUntil(this.destroy$)
        );
    }
    checkPoll(): void {
        this.startPollList.subscribe(isSet => {
            if (isSet === null) {
                this.hasValue.next(false);
                this.stopPollingOn();
            }
            // @ts-ignore
            if (isSet && isSet._id) {
                this.$resolves.next(isSet);
                if (isSet.state.toUpperCase() === 'COMPLETED' || isSet.state.toUpperCase() === 'COMPLETE') {
                    this.pollCount$.complete();
                    this.stopPollingOn();
                }
            }
        });
    }
    pollReset(): void {
        this.pollCount$.next(0);
        this.val = 0;
    }
    // tslint:disable-next-line:no-any
    stopPollingOn(): void {
        this.destroy$.next(true);
        this.hasValue.next(true);
        this.startPollList = of(null);
        this.val =0;
    }
    // @ts-ignore
    // tslint:disable-next-line:no-any
    handleError(error: any): Observable<any> {
        return throwError(error.error || 'Server error');
    }
}
