import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ChatService {
  baseUrl = environment.url + '/api/v1'
  constructor(
    private http: HttpClient
  ) { }

  private handleError(error: any): Observable<any> {
    console.log(error)
    return throwError(error.error || 'Server error');
  }
}

