import { CookieService } from 'ngx-cookie-service';
import { ChatService } from './chat.service';
import { Injectable } from '@angular/core';
import { BehaviorSubject, EMPTY, interval, Observable, of, ReplaySubject, Subscriber, Subscription, throwError } from 'rxjs';
import { catchError, first, map, switchMap, takeWhile } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { IChatHistory, IQuery } from '../shared/interfaces/query.interface';
import { IPrompt } from '../shared/interfaces/prompt.interface';
import { QueuePollClass } from '../shared/classes/queue-poll.class';
import { AnalyticsService } from './analytics.service';

@Injectable({
  providedIn: 'root'
})
export class QueryService extends QueuePollClass {
  baseUrl = environment.url + '/api/v1';
  // public isLoading: ReplaySubject<boolean> = new ReplaySubject(1);
  private $latestQuery: BehaviorSubject<IQuery | null> = new BehaviorSubject<IQuery | null>(null);
  public latestQuery$: Observable<IQuery | null> = this.$latestQuery.asObservable();
  private $loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public loading$: Observable<boolean> = this.$loading.asObservable();
  private $initialLoad: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public initialLoad$: Observable<boolean> = this.$initialLoad.asObservable();
  private firstMessage = false;
  constructor(
    public override http: HttpClient,
    private chatService: ChatService,
    private cookieService: CookieService,
    private analyiticsService: AnalyticsService
  ) {
    super(http);
  }
  setLoading(value: boolean): void {
    this.$loading.next(value);
  }
  unLoadConversation(): void {
    this.resetLastest().pipe(first()).subscribe((data: any) => {
      //this.cookieService.delete('session');
      this.$latestQuery.next(null);
    })
  }
  removeLastMessageLocal(): void {
    const currentMessage = this.$latestQuery.value;
    if (currentMessage) {
      if (currentMessage.chat_history[currentMessage.chat_history.length - 1].role === "user") {
        currentMessage.chat_history.pop();
        this.$latestQuery.next(currentMessage);
      }
      return;
    }
  }
  checkStatus(isTest: boolean, token?: string): void {
    const url = token ? `${this.baseUrl}/query/get-token/${token}` : `${this.baseUrl}/query/latest`;
    console.log('CHECK STATUS', url)
    if (this.cookieService.get('session') !== '') {
      this.$loading.next(true);
      this.createPoll({
        id: "queue",
        url: `${url}`,
        type: 'state'
      });
      this.pollCount$.subscribe(val => {
        if (val >= 5) {
          this.fetchMessage(isTest, token).subscribe(response => { }, error => { });
          this.pollReset();
        }
      });
      this.checkPoll();
      this.destroy$.pipe(first()).subscribe(ready => {
        if (ready) {
          this.$resolves.pipe(first()).subscribe((response: IQuery | null) => {
            if (response === null) {
              this.$loading.next(false);
              this.createMessage(isTest);
              return;
            }
            if (response && response.chat_history.length <= 0) {
              this.$loading.next(true);
              this.postFirstMessage(response._id, token);
            }
            this.updateMessage(response);
          });
        }
      }, fail => {
        console.log({ fail })
        this.$loading.next(false);
      });
    } else {
      console.log('no session')
    }
  }
  resetQuery(isTest: boolean): void {
    this.$loading.next(true);
    this.resetLastest().pipe(first()).subscribe((data: any) => {
      this.$loading.next(false);
      this.createMessage(isTest);
      if (data && data.chat_history.length <= 0) {
        this.$loading.next(true);
        this.postFirstMessage(data._id);
      }
      this.updateMessage(data);
    },
      (error) => {
        console.error('Error fetcresettinghing latest message:', error);
        this.$loading.next(false);
      }
    )
  }
  updateLatestObserver(response: IQuery): Observable<IQuery | null> {
    this.$latestQuery.next(response);
    return of(response);
  }
  updateStage(id: string, stage: number): Observable<void> {
    this.$loading.next(true);
    return new Observable<void>((observer) => {
      const subscription = this.setStage(id, stage).pipe(first()).subscribe((data: any) => {
        this.updateMessage(data);
      },
        (error) => {
          console.error('Error fetching latest message:', error);
          this.$loading.next(false);
        }
      )
      return () => {
        subscription.unsubscribe();
      };
    });
  }
  fetchMessage(isTest: boolean, token?: string): Observable<void> {
    this.$loading.next(true);
    return new Observable<void>((observer) => {
      const subscription = this.getLatestMessage(token).subscribe((data: any) => {
        if (data === null) {
          this.$loading.next(false);
          this.createMessage(isTest);
          observer.next();
        } else if (data && data.chat_history.length <= 0) {
          // this.postFirstMessage(data._id);
          observer.next();
        } else {
          this.updateMessage(data);
          observer.next();
        }
        observer.complete();
      }, (error) => {
        console.error('Error fetching latest message:', error);
        this.$loading.next(false);
        this.$initialLoad.next(false);
        observer.error(error);
      });

      return () => {
        subscription.unsubscribe();
      };
    });
  }
  acceptTerms(accept: boolean, message: string): void {
    this.$loading.next(true);
    this.latestQuery$.pipe(first()).subscribe((latestQuery: IQuery | null) => {
      if (latestQuery) {
        const id = latestQuery._id;
        this.acceptedTerms(id, accept, message).pipe().subscribe((data: any) => {
          this.$loading.next(false);
          this.updateMessage(data);
          this.analyiticsService.updateAnalyticsClass("terms_conditions")
        },
          (error) => {
            this.$loading.next(false);
          });
      }
    });
  }
  postFirstMessage(id: string, token?: string): void {
    this.$loading.next(true);
    this.firstAgentMessages(id).pipe().subscribe((data: any) => {
      if (!data.message) {
        this.updateMessage(data);
      }
    },
      (error) => {
        this.$loading.next(false);
      }
    )
  }
  createMessage(isTest: boolean): void {
    this.$loading.next(true);
    this.startNewMessage(isTest).pipe(first()).subscribe((data: any) => {
      this.updateMessage(data);
      if (data && data.chat_history.length <= 0) {
        this.$loading.next(false);
        // this.postFirstMessage(data._id, data.token);
      }
    },
      (error) => {
        console.error('Error creating message:', error);
        this.$loading.next(false);
      }
    )
  }
  getLatestMessage(token?: string): Observable<IQuery> {
    const url = token ? `${this.baseUrl}/query/get-token/${token}?modifiedOn=${new Date().toISOString()}` : `${this.baseUrl}/query/latest?modifiedOn=${new Date().toISOString()}`;
    return this.http
      .get<IQuery>(url)
      .pipe(catchError(this.handleError))
  }
  resetResponse(): Observable<IQuery> {
    const url = `${this.baseUrl}/query/reset-response?modifiedOn=${new Date().toISOString()}`;
    return this.http
      .post<IQuery>(url, {})
      .pipe(catchError(this.handleError))
  }
  resetLastest(): Observable<IQuery> {
    const url = `${this.baseUrl}/query/reset?modifiedOn=${new Date().toISOString()}`;
    return this.http
      .post<IQuery>(url, {})
      .pipe(catchError(this.handleError))
  }
  setStage(id: string, stage: number): Observable<IQuery> {
    const url = `${this.baseUrl}/query/update/stage?modifiedOn=${new Date().toISOString()}`;
    return this.http
      .post<IQuery>(url, { id, stage })
      .pipe(catchError(this.handleError))
  }
  acceptedTerms(id: string, accepted: boolean, message: string): Observable<IQuery> {
    const url = `${this.baseUrl}/terms/did-accept`;
    return this.http
      .post<IQuery>(url, { id, accepted, message })
      .pipe(catchError(this.handleError))
  }
  startNewMessage(isTest: boolean): Observable<IQuery> {
    const url = `${this.baseUrl}/query/new?test=${isTest}`;
    return this.http
      .post<IQuery>(url, { query: { name_prompt: environment.name, friend_prompt: environment.friend, goal_prompt: environment.goal } })
      .pipe(catchError(this.handleError))
  }
  appendMessageLocal(message: IChatHistory, time = 1000, id: string = "", type: string = "text", hold = false): Observable<void> {
    this.$loading.next(true);
    return new Observable<void>((observer) => {
      const currentMessage = this.$latestQuery.value;
      if (currentMessage && currentMessage.chat_history) {
        currentMessage.chat_history.push(message);
        if (id) {
          console.log('hold is', hold)
          this.newMessage({ id, role: "agent", content: message.content, mux_playback_ids: message.mux_playback_ids || [], type: message.type || type }, hold).pipe(first()).subscribe((data: any) => {
            this.$latestQuery.next(currentMessage);
            observer.next();
            observer.complete();
            this.$loading.next(false);
          });
        } else {
          this.$latestQuery.next(currentMessage);
        }
        setTimeout(() => {
          observer.next();
          observer.complete();
          this.$loading.next(false);
        }, time);
      } else {
        observer.error('No current message found');
      }
    });
  }
  updateMessage(newMessage: IQuery): void {
    const currentMessage = this.$latestQuery.value;
    if (!currentMessage) {
      this.$loading.next(false);
      this.$initialLoad.next(false);
      this.$latestQuery.next(newMessage);
    }
    if (currentMessage && newMessage.chat_history.length >= currentMessage.chat_history.length) {
      this.$loading.next(false);
      this.$initialLoad.next(false);
      this.$latestQuery.next(newMessage);
    }
    // if (JSON.stringify(newMessage) !== JSON.stringify(currentMessage)) {
    //   this.$loading.next(false);
    //   this.$initialLoad.next(false);
    //   this.$latestQuery.next(newMessage);
    // }
    //this.$loading.next(false);
  }
  getPageData(token: string | null): Observable<IPrompt> {
    const url = `${this.baseUrl}/prompt/token/${token}`;
    return this.http
      .get<IPrompt>(url)
      .pipe(catchError(this.handleError));
  }
  newMessage(message: { id: string, role: string, content: string, type: string, mux_playback_ids?: { policy: string; id: string; }[] }, hold = false): Observable<IQuery> {
    const url = `${this.baseUrl}/query/update/chat?modifiedOn=${new Date().toISOString()}`;
    const query = {
      ...message,
      ...{
        type: message.type,
        time: new Date().toISOString()
      }
    }
    return this.http
      .post<IQuery>(url, { ...{ id: message.id, hold }, ...{ query } })
      .pipe(catchError(this.handleError));
  }
  firstAgentMessage(id: string): Observable<IQuery> {
    const url = `${this.baseUrl}/query/update/chat?modifiedOn=${new Date().toISOString()}`;
    const query = {
      role: 'agent',
      type: 'text',
      content: "Hey. José Mourinho here. So your friend has scored an own goal? I can help.",
      time: new Date().toISOString()
    }
    return this.http
      .post<IQuery>(url, { ...{ id }, ...{ query } })
      .pipe(catchError(this.handleError));
  }
  firstAgentMessages(id: string): Observable<IQuery> {
    const url = `${this.baseUrl}/query/init/chat/messages?modifiedOn=${new Date().toISOString()}`;
    return this.http
      .post<IQuery>(url, { id })
      .pipe(catchError(this.handleError));
  }
  yourName(_id: string, query: IChatHistory): Observable<IQuery> {
    const url = `${this.baseUrl}/extraction/name?modifiedOn=${new Date().toISOString()}`;
    query._id = _id;
    return this.http
      .post<IQuery>(url, { query })
      .pipe(catchError(this.handleError));
  }
  friendName(id: string, query: IChatHistory): Observable<IQuery> {
    const url = `${this.baseUrl}/extraction/name/${id}?modifiedOn=${new Date().toISOString()}`;
    return this.http
      .post<IQuery>(url, { ...{ id }, ...{ query } })
      .pipe(catchError(this.handleError));
  }
  ownGoal(id: string, query: IChatHistory): Observable<IQuery> {
    const url = `${this.baseUrl}/extraction/goal/${id}?modifiedOn=${new Date().toISOString()}`;
    return this.http
      .post<IQuery>(url, { ...{ id }, ...{ query } })
      .pipe(catchError(this.handleError));
  }
  yourEmail(_id: string, query: IChatHistory): Observable<IQuery> {
    const url = `${this.baseUrl}/extraction/email?modifiedOn=${new Date().toISOString()}`;
    query._id = _id;
    return this.http
      .post<IQuery>(url, { query })
      .pipe(catchError(this.handleError));
  }
  completeEmailLoop(id: string, state = 'loading'): Observable<IQuery> {
    const url = `${this.baseUrl}/query/set-state/${id}?modifiedOn=${new Date().toISOString()}`;
    return this.http
      .post<IQuery>(url, { state })
      .pipe(catchError(this.handleError));
  }
  completeEndLoop(id: string): Observable<IQuery> {
    const url = `${this.baseUrl}/query/complete/end-loop?modifiedOn=${new Date().toISOString()}`;
    return this.http
      .post<IQuery>(url, { id })
      .pipe(catchError(this.handleError));
  }
  yourCode(_id: string, query: IChatHistory): Observable<IQuery> {
    const url = `${this.baseUrl}/extraction/code?modifiedOn=${new Date().toISOString()}`;
    query._id = _id;
    return this.http
      .post<IQuery>(url, { query })
      .pipe(catchError(this.handleError));
  }
}

